var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.lang.label)+" Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.name","rules":"","name":"The Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Name","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.title","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Title","name":"title","fou":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1)]),_c('label',[_vm._v("Biography")]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.biography),function(item,itemIndex){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"label","rules":"required","name":"The Label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:itemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":'Label '+(itemIndex+1)},model:{value:(_vm.formData.biography[itemIndex].label),callback:function ($$v) {_vm.$set(_vm.formData.biography[itemIndex], "label", $$v)},expression:"formData.biography[itemIndex].label"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"value","rules":"required","name":"The Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:itemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":'Value '+(itemIndex+1)},model:{value:(_vm.formData.biography[itemIndex].value),callback:function ($$v) {_vm.$set(_vm.formData.biography[itemIndex], "value", $$v)},expression:"formData.biography[itemIndex].value"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.biography, itemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.biography)}}},[_c('i',{staticClass:"fa fa-plus"})])],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }